
import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Route } from 'vue-router';
import { HelpAndFAQsModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { Faq } from '@/api/contracts/helpAndFAQs/faq';
import { SidebarMixin } from '@/mixins/sidebarMixin';

const helpAndFAQsModule = getModule(HelpAndFAQsModule);

@Component({
  mixins: [SidebarMixin],
})
export default class Faqs extends LoggedInComponentBase {
  public searchKeyword: string = '';
  public faqs_: Faq[] = [];

  public get faqs(): Faq[] {
    return helpAndFAQsModule.faqs;
  }

  public allFaqs() {
    this.faqs_ = helpAndFAQsModule.faqs;
  }

  public filterFaqs() {
    if (!this.searchKeyword) {
      this.allFaqs();
    } else {
      this.faqs_ = this.faqs_.filter(this.filterBysearchKeyword);
    }
  }

  public findTextMatch(articleText: string) {
    return articleText.toLowerCase().includes(this.searchKeyword.toLowerCase());
  }

  public filterBysearchKeyword(article: Faq) {
    if (!this.searchKeyword) return true; // return all with no keyword

    // search in content fields
    return (
      this.findTextMatch(article.title) ||
      this.findTextMatch(article.question) ||
      this.findTextMatch(article.answer)
    );
  }

  public created() {
    this.allFaqs();
  }

  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: Faqs) => void)) => void
  ): Promise<void> {
    try {
      await helpAndFAQsModule.getFaqs();
      next();
    } catch {
      next(false);
    }
  }
}
